import { useEffect } from "react";
import useLadyService from "@/services/LadyService";
import useTitle from "@/hooks/useTitle";

const StatPage = () => {
  const { t, scrollToTop } = useLadyService();



  return (
    <main>
      <section className={"reviews-page__container"}>
        <h1>{t("mystat")}</h1>
        <h3 className="text-center mt-12">{t("soon")}</h3>
      </section>
    </main>
  );
};

export default StatPage;
