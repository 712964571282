import useLadyService from "@/services/LadyService";
import useTitle from "@/hooks/useTitle";
import AccountCustomer from "./customer/AccountCustomer";
import {Loader} from "../../ui";
import AccountModel from "./model/AccountModel";
import AgencyPage from "../agency/AgencyPage";
import {useEffect, useState} from "react";
import {makeRequest} from "../../../services/makeRequest";
import {setUserInfoAlreadyExist} from "../../../stores/slices/userSlice";
import {
  setActionsCountStatus, setBlockedCountriesList,
  setCitiesListStatus, setCityIdStatus, setCityStatus, setEmailStatus,
  setEmailVerifiedAtStatus,
  setFiltersCountStatus, setLatitudeStatus, setLongitudeStatus,
  setNotifyEmailStatus,
  setNotifyTelegramStatus,
  setNotifyWhatsappStatus,
  setPhoneStatus,
  setProfilesCountStatus,
  setProfileStatus,
  setSalonStatus, setTelegramStatus, setWhatsappStatus
} from "../../../stores/slices/userProfileSlice";
import {useLocation} from "react-router-dom";

import './accountPage.scss'
import AccountAgency from "./agency/AccountAgency";
const AccountPage = () => {
  const {
    t,
    userType,
    scrollToTop,
    scrollToHash,
    dispatch,
    token,
    lang,
  } = useLadyService();
  const [isLoading, setIsLoading] = useState(true);

  const {hash} = useLocation();

  useEffect(() => {
    if (!hash) {
      scrollToTop();
    } else {
      if(isLoading){
        scrollToHash();
      }
    }
  }, [isLoading, hash]);

  useEffect(() => {
    const fetchData = async () => {
      // setValueLoad(false);
      setIsLoading(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({route, method});

        if (data) {
          const result = data.data;

          dispatch(setProfileStatus(result.profiles_status))
          dispatch(setFiltersCountStatus(result.filters_count))
          dispatch(setActionsCountStatus(result.actions_count))

          dispatch(setTelegramStatus(result.telegram))
          dispatch(setWhatsappStatus(result.whatsapp))
          dispatch(setEmailStatus(result.email))
          dispatch(setEmailVerifiedAtStatus(result.email_verified_at))
          dispatch(setPhoneStatus(result.phone))
          dispatch(setProfilesCountStatus(result.profiles_count))

          dispatch(setSalonStatus(result.salon))

          dispatch(setNotifyEmailStatus(result.notify_email))
          dispatch(setNotifyTelegramStatus(result.notify_telegram))
          dispatch(setNotifyWhatsappStatus(result.notify_whatsapp))

          dispatch(setLatitudeStatus(result.latitude))
          dispatch(setLongitudeStatus(result.longitude))
          dispatch(setCityStatus(result.city))
          dispatch(setCityIdStatus(result.city_id))
          dispatch(setBlockedCountriesList(result.blocked_countries))

          console.log(result)
          dispatch(setUserInfoAlreadyExist());
        }

        setIsLoading(false);
        // setValueLoad(true);
      } catch (error) {
      }
    };

    token && fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `services/all`;
        const method = "GET";
        const payload = {lang};

        const {cities} = await makeRequest({route, method, payload});
        dispatch(setCitiesListStatus(cities))
      } catch (error) {
      }
    };

    token && lang && fetchData();
  }, [token, lang]);

  const checkUserType = () => {
    switch (userType){
      case 'customer': return isLoading ? <Loader/> : <AccountCustomer/>

      case 'indi': return isLoading ? <Loader/> : <AccountModel/>

      case 'agency': return isLoading ? <Loader/> : <AccountAgency/>

      default: return <Loader height={100} decimal={'%'}/>
    }
  }

  useTitle(t("profuser") + " - Lady4Love");

  return (
    <main className={"container w-100"}>
      <h1>{t("profuser")}</h1>

      {checkUserType()}

    </main>
  );
};

export default AccountPage;
