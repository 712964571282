import { useSelector } from "react-redux";
import {
  getUserCurrentCity,
} from "@/stores/slices/userSlice";
import { getTitle } from "@/stores/slices/titleSlice";
import useDefiningCity from "@/hooks/useDefiningCity";
import useDefiningLang from "@/hooks/useDefiningLang";
import useNavigateToMain from "@/hooks/useNavigateToMain";
import { useLocation } from "react-router-dom";
import IndiLayoutLk from "./IndiLayoutLk";
import AgencyLayoutLk from "./AgencyLayoutLk";
import Layout from "./Layout";
import useLadyService from "@/services/LadyService";
import { useEffect } from "react";
import { getUserRegion } from "@/stores/slices/userSlice";
import { setGmapsApiReady } from "@/stores/slices/mapModelSlice";
import useLanguageRedirect from "@/hooks/useLanguageRedirect";

const MainLayout = ({ children }) => {
  const { userType, scrollToTop, dispatch, userCity, t, lang } =
    useLadyService();

  const { pathname } = useLocation();

  useDefiningLang();
  useDefiningCity();
  useNavigateToMain();
  useLanguageRedirect();

  const currentCity = useSelector(getUserCurrentCity);
  const region = useSelector(getUserRegion);

  const postDesc = t("description")?.replace(/%([^%]+)%/g, (match, p1) => {
    switch (p1) {
      case "City":
        return currentCity.title;
      case "Country":
        return region;
      default:
        return match;
    }
  });

  const postCity = t("incity")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : ""
  );
  const postTitle =
    t("title")?.replace(
      /%([^%]+)%/g,
      currentCity.title ? currentCity.title : ""
    ) +
    " " +
    postCity;
  const title = useSelector(getTitle);
  const titleString = !!title.length
    ? title
        .filter((item) => !!item)
        .map((item) => t(item))
        .join(", ")
    : "";

  const titleParams = titleString
    ? `${userCity.title} - ${t("titleselect")}: ${titleString}`
    : postTitle;

  const mapLink = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA81vyhvrBl3jUTFDK6LwYNm317prcxfVo&libraries=places&language=en`;
  const loadScript = (retryCount = 0) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = mapLink;
      script.async = true;

      script.onload = () => {
        console.log("Google Maps API loaded successfully.");
        dispatch(setGmapsApiReady(true));
        resolve();
      };

      script.onerror = () => {
        console.error("Google Maps API failed to load.");
        document.body.removeChild(script);

        if (retryCount < 5) {
          console.log(`Retrying to load Google Maps API... Attempt: ${retryCount + 1}`);
          setTimeout(() => {
            loadScript(retryCount + 1).then(resolve).catch(reject);
          }, 1000);
        } else {
          reject(new Error("Google Maps API failed to load after multiple attempts."));
        }
      };

      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript()
      .then(() => {
        console.log("Google Maps API loaded successfully.");
        dispatch(setGmapsApiReady(true));
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (pathname.includes("/lk") && (userType === "indi" || userType === "admin" || userType === "moderator")) {
    return (
      !!lang && <IndiLayoutLk
        titleParams={t("profuser") + " - Lady4Love"}
        postDesc={postDesc}
      >
        {children}
      </IndiLayoutLk>
    );
  }

  if (pathname.includes("/lk") && userType === "agency") {
    return (
      <AgencyLayoutLk titleParams={titleParams} postDesc={postDesc}>
        {children}
      </AgencyLayoutLk>
    );
  }

  return (
    <Layout
      titleParams={titleParams}
      postDesc={
        titleString ? `${t("h1main")}: ${titleString} - ${postCity}` : postDesc
      }
    >
      {children}
    </Layout>
  );
};

export default MainLayout;
