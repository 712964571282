import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {setNotDisabledCheckbox} from "./modelsState";
import baseUrl from "@/services/apiConfig";

const initialState = {
    idForEditFilter: false,
    updatedFilterItem: false,
    isFilterAlreadyExist: false,
    isRangeLoad: false,
    isSearchFilter: false,
    fastFilters: false,
    place: [],
    breast: [],
    breast_size: [],
    breast_type: [],
    penis_size: [],
    status: [],
    gender: [],
    sex: [],
    for: [],
    tag: [],
    services: [],
    min_tariffs: [],
    language: [],
    nation: [],
    last_visit: [],
    radius: {
        value: [],
        latitude: 0,
        longitude: 0,
    },
    address: [],
    accordionSelected: [
        {status: []},
        {breast: []},
        {gender: []},
        {services: []},
        {language: []},
        {nation: []},
        {radius: []},
    ],
    range: {
        price: {
            min: 0,
            max: 0,
        },
        age: {
            min: 0,
            max: 0,
        },
        weight: {
            min: 0,
            max: 0,
        },
        height: {
            min: 0,
            max: 0,
        },
    },
    defaultRange: {
        price: {
            min: 0,
            max: 0,
        },
        age: {
            min: 0,
            max: 0,
        },
        weight: {
            min: 0,
            max: 0,
        },
        height: {
            min: 0,
            max: 0,
        },
    },
};

export const filterState = createSlice({
    name: "filter",
    initialState,
    reducers: {
        setIsFilterAlreadyExist: (state) => {
            state.isFilterAlreadyExist = true
        },
        setFilterEditId: (state, action) => {
            state.idForEditFilter = action.payload
        },
        setUpdatedFIlterItem: (state, action) => {
            state.updatedFilterItem = action.payload
        },
        setIsRangeLoad: (state, action) => {
            state.isRangeLoad = action.payload;
        },
        resetAddressValue: (state) => {
            state.address = [];
        },
        setAddressValue: (state, action) => {
            state.address = [action.payload];
        },
        resetRadiusValue: (state) => {
            state.radius.value = [];
        },
        setRadiusValue: (state, action) => {
            state.radius.value = [action.payload];
        },
        setRadiusCoords: (state, action) => {
            const {latitude, longitude} = action.payload;
            state.radius.latitude = latitude;
            state.radius.longitude = longitude;
        },
        resetRadiusCoords: (state) => {
            state.radius.latitude = 0;
            state.radius.longitude = 0;
        },
        resetStateAcc: (state, action) => {
            const stateName = action.payload;
            state.gender = state[stateName].filter(
                (item) => !(/^\d+$/.test(item) && item !== "1")
            );
        },
        resetToggleAcc: (state, action) => {
            const stateName = action.payload;
            const genderObject = state.accordionSelected.find(
                (item) => stateName in item
            );
            genderObject[stateName] = genderObject[stateName].filter(
                (item) => !(/^\d+$/.test(item) && item !== "1")
            );
        },
        resetAccordionByStatus: (state, action) => {
            const {accordionName, arr} = action.payload;

            const accordionIndex = state.accordionSelected.findIndex(item => item.hasOwnProperty(accordionName));

            if (accordionIndex !== -1) {
                const currentArray = state.accordionSelected[accordionIndex][accordionName];

                state.accordionSelected[accordionIndex][accordionName] = currentArray.filter(item => !arr.includes(item));
            }
        },
        setPlaceFilter: (state, action) => {
            state.place = [action.payload];
        },
        setSearchFilter: (state, action) => {
            state.isSearchFilter = action.payload;
        },
        setFastFilter: (state, action) => {
            state.fastFilters = action.payload;
        },
        rangeChange: (state, action) => {
            const {name, min, max} = action.payload;
            if (state.range[name]) {
                state.range[name].min = min;
                state.range[name].max = max;
            }
        },
        setDefaultRange: (state, action) => {
            const {name, min, max} = action.payload;
            if (state.defaultRange[name]) {
                state.defaultRange[name].min = min;
                state.defaultRange[name].max = max;
            }
        },
        resetDefaultRange: (state) => {
            state.defaultRange = {
                price: {
                    min: 0,
                    max: 0,
                },
                age: {
                    min: 0,
                    max: 0,
                },
                weight: {
                    min: 0,
                    max: 0,
                },
                height: {
                    min: 0,
                    max: 0,
                },
            }
        },
        resetRange: (state, action) => {
            const {name, min, max} = action.payload;
            if (state.range[name]) {
                state.range[name].min = min;
                state.range[name].max = max;
            }
        },
        toggleSelected: (state, action) => {
            const checkboxValue = String(action.payload.value);
            const accordionId = String(action.payload.name);
            const currentSelectedIndex = state.accordionSelected.findIndex(
                (item) => Object.keys(item)[0] === accordionId
            );
            const currentSelected =
                currentSelectedIndex !== -1
                    ? state.accordionSelected[currentSelectedIndex][accordionId]
                    : [];

            const isSelected = currentSelected.includes(checkboxValue);

            let updatedSelectedCheckboxes = [];
            if (!isSelected) {
                updatedSelectedCheckboxes = [...currentSelected, checkboxValue];
            } else {
                updatedSelectedCheckboxes = currentSelected.filter(
                    (item) => item !== checkboxValue
                );
            }

            if (currentSelectedIndex !== -1) {
                state.accordionSelected[currentSelectedIndex][accordionId] =
                    updatedSelectedCheckboxes;
            } else {
                state.accordionSelected.push({
                    [accordionId]: updatedSelectedCheckboxes,
                });
            }
        },
        toggleSelectedArr: (state, action) => {
            const checkboxValue = action.payload.value;
            const accordionId = String(action.payload.name);
            const currentSelectedIndex = state.accordionSelected.findIndex(
                (item) => Object.keys(item)[0] === accordionId
            );

            state.accordionSelected[currentSelectedIndex][accordionId] = checkboxValue
        },
        resetSelected: (state, action) => {
            const accordionKey = action.payload;
            state.accordionSelected = state.accordionSelected.map((accordion) => {
                if (accordion.hasOwnProperty(accordionKey)) {
                    return {
                        [accordionKey]: [],
                    };
                }
                return accordion;
            });
        },

        toggleState: (state, action) => {
            const value = String(action.payload.value);
            const stateName = String(action.payload.StateName);
            const isCheckbox = action.payload.isCheckbox;

            const isSelected = state[stateName].includes(value);

            let updatedSelectedCheckboxes = [];

            if (!isSelected) {
                if (isCheckbox) {
                    state[stateName] = []
                }
                updatedSelectedCheckboxes = [...state[stateName], value];
            } else {
                updatedSelectedCheckboxes = state[stateName].filter(
                    (item) => item !== value
                );
            }

            state[stateName] = updatedSelectedCheckboxes;
        },
        toggleStateArr: (state, action) => {
            const value = action.payload.value;
            const stateName = action.payload.StateName;

            state[stateName] = value
        },
        resetState: (state, action) => {
            const stateName = action.payload;
            state[stateName] = [];
        },
        resetAll: (state) => {
            state.place = [];
            state.breast = [];
            state.breast_size = [];
            state.breast_type = [];
            state.penis_size = [];
            state.status = [];
            state.gender = [];
            state.sex = [];
            state.for = [];
            state.services = [];
            state.min_tariffs = [];
            state.language = [];
            state.nation = [];
            state.last_visit = [];
            state.radius = {
                value: [],
                latitude: 0,
                longitude: 0,
            };
            state.address = [];
            state.place = [];
            state.accordionSelected = [
                {status: []},
                {breast: []},
                {gender: []},
                {services: []},
                {language: []},
                {nation: []},
                {radius: []},
            ];
            state.range = {
                price: {
                    min: state.defaultRange.price.min,
                    max: state.defaultRange.price.max,
                },
                age: {
                    min: state.defaultRange.age.min,
                    max: state.defaultRange.age.max,
                },
                weight: {
                    min: state.defaultRange.weight.min,
                    max: state.defaultRange.weight.max,
                },
                height: {
                    min: state.defaultRange.height.min,
                    max: state.defaultRange.height.max,
                },
            };
        },
        setRadioValue: (state, action) => {
            const value = action.payload.count;
            const stateName = action.payload.StateName;

            state[stateName] = [value];
        },
        getState: (state) => {
            try {
                return JSON.stringify(state);
            } catch (e) {
                console.error("Failed to save state", e);
            }
        },

        restoreState: (state, action) => {
            try {
                if (action.payload) {
                    const parsedState = action.payload.payload.filter;
                    Object.keys(parsedState).forEach(key => {
                        if (key !== 'idForEditFilter') {
                            state[key] = parsedState[key];
                        }
                    });
                }
            } catch (e) {
                console.error("Failed to load state", e);
            }
        },
    },
});

export const {
    resetAccordionByStatus,
    setFilterEditId,
    setIsFilterAlreadyExist,
    resetDefaultRange,
    toggleSelectedArr,
    toggleStateArr,
    setIsRangeLoad,
    setRadioValue,
    setDefaultRange,
    resetAddressValue,
    setAddressValue,
    resetRadiusValue,
    setRadiusValue,
    resetStateAcc,
    setPlaceFilter,
    setSearchFilter,
    setFastFilter,
    resetRange,
    toggleSelected,
    resetSelected,
    toggleState,
    resetState,
    rangeChange,
    resetAll,
    setIsFilterEmpty,
    resetToggleAcc,
    setRadiusCoords,
    resetRadiusCoords,
    setUpdatedFIlterItem,
    // setIsTriggerProfiles,
    getState,
    restoreState,
} = filterState.actions;

export function extractKeysAndNumbers(obj) {
    let keysWithValueTrue = [];
    let keyToNumbersMap = {};

    function extract(obj, parentKey) {
        for (let key in obj) {
            if (typeof obj[key] === "boolean") {
                if (obj[key] === true) {
                    if (!parentKey) {
                        keysWithValueTrue.push(key);
                    } else {
                        if (!keyToNumbersMap[parentKey]) {
                            keyToNumbersMap[parentKey] = [];
                        }
                        keyToNumbersMap[parentKey].push(key);
                    }
                }
            } else if (typeof obj[key] === "object") {
                extract(obj[key], key);
            }
        }
    }

    extract(obj, null);

    return {keysWithValueTrue, ...keyToNumbersMap};
}

export const fetchRanges = (cityId, lang) => async (dispatch) => {

    try {
        axios
            .get(`${baseUrl}filters/${cityId}?lang=${lang}`)
            .then((response) => {
                const data = response.data.data.filters;
                dispatch(
                    setDefaultRange({
                        name: "price",
                        min: data.price_min,
                        max: data.price_max,
                    })
                );
                dispatch(
                    setDefaultRange({name: "age", min: data.age_min, max: data.age_max})
                );
                dispatch(
                    setDefaultRange({
                        name: "weight",
                        min: data.weight_min,
                        max: data.weight_max,
                    })
                );
                dispatch(
                    setDefaultRange({
                        name: "height",
                        min: data.height_min,
                        max: data.height_max,
                    })
                );
                const result = extractKeysAndNumbers(data);
                dispatch(setNotDisabledCheckbox(result));
            })
            .then(
                dispatch(setIsRangeLoad(true),
                    dispatch(setIsFilterAlreadyExist())
                ));
    } catch (error) {
    }
};


export const allFilter = (state) => state.filter;
export const getFilterEditId = (state) => state.filter.idForEditFilter;
export const isFilterSearch = (state) => state.filter.isSearchFilter;
export const getFastFilters = (state) => state.filter.fastFilters;
// export const isTriggerProfiles = (state) => state.filter.isTriggerProfiles;
export const filterPlace = (state) => state.filter.place;
export const getUpdatedFilterItem = (state) => state.filter.updatedFilterItem;
export const filterFor = (state) => state.filter.for;
export const filterBreast = (state) => state.filter.breast;
export const filterBreastSize = (state) => state.filter.breast_size;
export const filterBreastType = (state) => state.filter.breast_type;
export const filterPenisSize = (state) => state.filter.penis_size;
export const filterPenis = (state) => state.filter.penis;
export const filterStatus = (state) => state.filter.status;
export const filterGender = (state) => state.filter.gender;
export const filterSex = (state) => state.filter.sex;
export const filterTag = (state) => state.filter.tag;
export const filterServices = (state) => state.filter.services;
export const filterNation = (state) => state.filter.nation;
export const filterLanguage = (state) => state.filter.language;
export const filterLastVisit = (state) => state.filter.last_visit;
export const filterRadius = (state) => state.filter.radius;
export const filterAddress = (state) => state.filter.address;
export const filterMinTariffs = (state) => state.filter.min_tariffs;
export const rangeState = (state) => state.filter.range;
export const defaultRangeState = (state) => state.filter.defaultRange;
export const rangePriceState = (state) => state.filter.range.price;
export const rangeAgeState = (state) => state.filter.range.age;
export const filterSelected = (state) => state.filter.accordionSelected;
export const getIsRangeLoad = (state) => state.filter.isRangeLoad;
export const getUserFilterStatus = (state) => state.filter.isFilterAlreadyExist;

export default filterState.reducer;
