import { Checkbox, Skeleton, Dropdown } from "@/components/ui";
import React, { useEffect, useState } from "react";

import "./history.scss";
import { makeRequest } from "@/services/makeRequest";
import GirlCard from "../../cards/girlCard/GirlCard";
import useTitle from "@/hooks/useTitle";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { HistoryCard } from "./HistoryCard";

const History = () => {
  const { t, token, windowWidth, userType, dispatch, lang, scrollToTop } = useLadyService();

  const [isLoading, setIsLoading] = useState(false);
  const favoriteCheckbox = {
    main_title: windowWidth > 479.98 ? t("more") : t("smartfilter"),
    options: [
      {
        title: t("favorites"),
        value: "favorites",
      },
      {
        title: t("views"),
        value: "visit",
      },
      {
        title: t("i-notes"),
        value: "note",
      },
      {
        title: t("reviews"),
        value: "review",
      },
      {
        title: t("i-complaint"),
        value: "ticket",
      },

      {
        title: t("videochats"),
        value: "call",
      },
      {
        title: t("erocontent"),
        value: "ero",
      },
      {
        title: t("myorder"),
        value: "booking",
      },
    ],
  };

  const [activeIndex, setActiveIndex] = useState("favorites");

  const handleCheckbox = (type) => {
    if (!token && type !== "favorites") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("interaction"));
      return;
    }
    setActiveIndex(type);
  };

  const [data, setData] = useState([]);
  const [emptyData, setEmptyData] = useState(false);

  const dataForDefault = JSON.parse(localStorage.getItem("favorites"));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(false);
      setEmptyData(false);
      setData([]);
      try {
        setIsLoading(false);
        const route = "user/favorites";
        const method = "GET";

        const result = await makeRequest({ route, method });

        if (result) {
          setData(result.data);
        }
        setIsLoading(true);
      } catch (error) {
        setIsLoading(true);
      }
    };

    token && lang && activeIndex === "favorites" && fetchData();
  }, [lang, activeIndex]);

  useEffect(() => {
    const fetchData = async () => {
      setEmptyData(false);
      const routeType = "profiles";
      try {
        setIsLoading(false);
        if (!!!dataForDefault?.length) {
          setEmptyData(true);
          setIsLoading(true);
          return;
        }
        const route = routeType;
        const method = "GET";
        const payload = {
          ...(activeIndex === "favorites" && {
            slug: dataForDefault?.join(","),
          }),
        };

        const result = await makeRequest({ route, method, payload });

        if (result) {
          setData(result.profiles);
          setIsLoading(true);
        }
      } catch (error) {}
    };

    !token && lang && fetchData();
  }, [token, lang, activeIndex]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(false);
      setData([]);
      setEmptyData(false);

      try {
        const route = `user/actions/${activeIndex}`;
        const method = "GET";

        const result = await makeRequest({ route, method });

        if (result) {
          setData(result);
          console.log(result)

        }
        if (!result.length) {
          setEmptyData(true);
        }

        setIsLoading(true);
      } catch (error) {
        setIsLoading(true);
      }
    };

    activeIndex !== "favorites" && fetchData();
  }, [activeIndex]);

  const skeletons = [...Array(12)].map((_, index) => <Skeleton key={index} />);

  const checkboxNavTemplate = (i, index) => (
    <Checkbox
      key={index}
      id={index}
      checked={i.value === activeIndex}
      title={i.title}
      value={i.value}
      important
      onChange={() => handleCheckbox(i.value)}
    />
  );

  useTitle(t("adsinteraction"));

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <main className={'favorites-page'}>
      <section className="navigation__container">
        <h1>{userType === "indi" ? t("favorites") : t("adsinteraction")}</h1>

        {userType === "indi" ? null : (
          <nav className="navigation__nav">
            {favoriteCheckbox.options.map((i, index) => checkboxNavTemplate(i, index))}

            {windowWidth < 1000 &&
              <Dropdown
                title={favoriteCheckbox.main_title}
                size={"xs"}
                clazzWrapper={"navigation__more"}
              >
                {favoriteCheckbox.options.map((i, index = 10) => checkboxNavTemplate(i, index))}
              </Dropdown>
            }
          </nav>
        )}

        <div className="grid-cards">
          {isLoading &&
            !emptyData &&
            data.map((data, index) => {
              return userType === "indi" ? (
                <GirlCard
                  props={data.slug ? data : data.profile}
                  key={index}
                  big
                />
              ) : (
                <HistoryCard key={index} data={data} activeIndex={activeIndex}/>
              );
            })}
          {isLoading && emptyData && <h3>{t("emptyrecord")}</h3>}
          {!isLoading && skeletons}
        </div>
      </section>
    </main>
  );
};
export default History;
