import {
  CountriesFilter,
  CheckPhone,
  Rules,
  InfoForm, InfoBlock,
} from "../components";

import {
  InputInLabel,
  Toggle,
} from "@/components/ui";

import {useState} from "react";

import useLadyService from "@/services/LadyService";

import NotifyBlock from "../components/notifyBlock/NotifyBlock";
import {PersonalData} from "../components/PersonalData.js";
import {AgencyDesc} from "./agencyDesc/AgencyDesc.js";
import {MediaZone} from "./mediaZone/MediaZone.js";
import {Promos} from "./mediaZone/Promos.js";
import TarifsGlobal from "../createProfile/pageComponent/stepSection/tarifs/TarifsGlobal.js";
import {Interior} from "../createProfile/pageComponent/stepSection/media/Interior/index.js";
import AccountActions from "../components/accountActions/AccountActions";
import useUserProfileService from "../../../../services/UserProfileService";

import "./AccountAgency.scss";
import ToggleSalon from "../components/toggleSalon/ToggleSalon";

const AccountModel = () => {
  const {windowWidth, t, userBalance} = useLadyService();
  const {profileCountStatus, blockedCountriesList} = useUserProfileService();

  let data = {
    blocked_countries: blockedCountriesList
  }

  const isSmallTablet = windowWidth < 1000.98;

  const goToAncketa = isSmallTablet ? t("toads") : t("browseprofiles");

  const goToBank = isSmallTablet ? t("tobalance") : t("gotobalance");

  const [videoPresentationValue, setVideoPresentationValue] = useState("");

  const [priceForOneModel, setPriceFormOneModel] = useState("");
  const [presentationValue, setPresentationValue] = useState("");

  const [dataInterior, setDataInterior] = useState({files: []});

  return (
    <div className={"account-page__root"}>
      <div className={"account-page__main"}>
        <PersonalData/>

        <hr/>

        {isSmallTablet && (
          <>
            <Rules/>
            <hr/>
          </>
        )}

        <AgencyDesc/>

        <hr/>

        <NotifyBlock/>

        <InfoForm/>

        <hr/>

        <div className="account-page__toggles-wrapper">
          <ToggleSalon/>

          <div className="account-page__toggles">
            <div className="account-page__activate">
              <h3>{t("modelpreviews")}</h3>

              <Toggle
                id={"isActiveSalon"}
                // checked={!!isBrothel}
                // onClick={handleActivateBrothel}
              />

              <InputInLabel
                price={"erocoin"}
                type={"number"}
                id={"upload-price"}
                value={priceForOneModel}
                onChange={(e) => setPriceFormOneModel(e)}
              >
                {t("tariffs")}
              </InputInLabel>
            </div>

            <span>{t("tippyagencywatch")}</span>
          </div>
        </div>

        <hr/>

        <div className={`d-flex justify-sb gap-16 mobile-fd-col`}>
          <MediaZone
            h3={t("agencylogo")}
            title={t("addagencylogo")}
            subtitle={t("prefersize") + " 360х240 px"}
            isBigPhoto
          />

          <MediaZone
            presentationValue={presentationValue}
            setPresentationValue={setPresentationValue}
            isBigPhoto={true}
            setVideoPresentationValue={setVideoPresentationValue}
            videoPresentationValue={videoPresentationValue}
            h3={t("videopresentation")}
            title={
              t("uploadvideo") + " (" + t("maxval").toLowerCase() + " 20Mb)"
            }
            isVideo={true}
          />
        </div>

        <MediaZone
          h3={t("agenсycover")}
          title={t("addaagenсycover")}
          subtitle={t("prefersize") + " 1440х385 px"}
          isBigPhoto
        />

        <div>
          <h3>{t("interiorphoto")}</h3>

          <Interior
            show={"interior"}
            data={dataInterior}
            setData={setDataInterior}
            formData={{}}
            setFormData={{}}
          />
        </div>

        <hr/>

        <Promos/>

        <hr/>

        <div className={"account-page__info"}>
          <InfoBlock
            text={!!profileCountStatus ? t("yourads") : t("addads")}
            count={!!profileCountStatus ? profileCountStatus : ''}
            link={'/lk/agency/profiles'}
            linkText={goToAncketa}
          />

          <InfoBlock
            text={t("yourbalance")}
            count={userBalance}
            balance
            link={'/lk/balance'}
            linkText={goToBank}
          />
        </div>

        <hr/>

        <div>
          <h2>{t('defaultset')}</h2>

          <p>{t('customize')}</p>

          <TarifsGlobal isAgency={true}/>

          <CheckPhone/>

          <CountriesFilter data={data}/>
        </div>

        <hr/>

        <AccountActions/>

      </div>

      {!isSmallTablet && <Rules/>}
    </div>
  );
};

export default AccountModel;
