import useLadyService from "@/services/LadyService";
import {Button, Icon, InputInLabel, Toggle} from "../../../../ui";
import {MediaZone} from "./MediaZone";
import debounce from "lodash.debounce";
import {useCallback, useState} from "react";

export const Promos = () => {
  const {t} = useLadyService();
  const [inputs, setInputs] = useState([]);
  const addInput = () => {
    setInputs([...inputs, {index: inputs.length, value: "", active: false}]);
  };

  const removeInput = (index) => {
    setInputs(inputs.filter((input) => input.index !== index));
  };

  const toggleInput = (index) => {
    const value = inputs.filter((item) => item.index === index)[0].active;

    setInputs(
      inputs.map((input) =>
        input.index === index ? {...input, active: !value} : input
      )
    );
  };

  const handleInputChange = useCallback(
    debounce((newValue, index) => {
      setInputs(prev =>
        prev.map((input) =>
          input.index === index ? {...input, value: newValue, active: true} : input
        )
      );
    }, 500),
    []
  );

  const Promo = ({item, index}) => {
    const [value, setValue] = useState(item.value);

    return (
      <div className={`mb-16`}>
        <div className="d-flex gap-16 align-center">
          <Toggle
            id={"isActivePromo" + index}
            checked={!!item.active}
            onChange={() => toggleInput(index)}
          />

          <InputInLabel
            type={"text"}
            id={"promos-name" + index}
            clazz={"w-100"}
            value={value}
            disabled={!item.active}
            placeholder={t("regulars")}
            onChange={(e) => {
              setValue(e.target.value)
              handleInputChange(e.target.value, index);
            }}
          >
            {t("name") + " " + t("agencypromos").split(" ")[0].toLowerCase()}
          </InputInLabel>

          <button className={`tarifs-row-trash icon icon-xl icon--trash _red`}
                  type={'button'}
                  onClick={() => removeInput(index)}
          />
        </div>

        <div className={`d-flex gap-16 mt-16`}>
          <MediaZone
            w_100
            isH3={false}
            title={t("uploadpreview") + " 360*270 px"}
          />

          <MediaZone
            w_100
            isBigPhoto
            isH3={false}
            title={t("banner") + " 1200*900 px)"}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex justify-sb align-center">
        <h3>{t("agencypromos")}</h3>
        <Button
          size={"s"}
          clazz={
            "button_outline--green-accent justify-center float-right"
          }
          onClick={addInput}
        >
          <Icon spritePath={"plus"} size={"s"}/>

          {t("addad")}
        </Button>
      </div>

      {inputs.map((item, index) => {
        return (
          <>
            <Promo key={item.id} item={item} index={index}/>
            {index - 1 ? null : <hr/>}
          </>
        )
      })}
    </div>
  );
};
