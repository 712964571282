import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./toggle.scss";

const Toggle = (props) => {
  const {
    id,
    name,
    onChange,
    link,
    title,
    titleDefault,
    disabled,
    counter,
    checked,
    onClick,
    clazz = "",
    readOnly
  } = props;

  return (
    <div className={`toggle-switch${!!titleDefault ? " defaulttitle" : ""}${disabled ? " _disabled" : ""}${clazz ? ` ${clazz}` : ""}`}>
      {link ? (
        <Link
          to={`${link}`}
          className="hover-line p3"
          data-counter={counter === 0 ? null : counter}
        >
          {title}
        </Link>
      ) : null}

      {!!titleDefault ? (
        <span className="p3 color-main">{titleDefault}</span>
      ) : null}

      <input
        onClick={onClick}
        id={id}
        readOnly={readOnly}
        name={name}
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
    </div>
  );
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Toggle;
