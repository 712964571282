import {
  VerificationAndHealthCheck,
  VerHeader,
  AddHeader,
} from "./pageComponets";
import { useRef, useState, useCallback } from "react";
import { useEffect } from "react";
import AdCard from "./card/AdCard";
import { makeRequest } from "@/services/makeRequest";
import moment from "moment";

import { ImportAdPopup, MakeEliteAdOnePopup } from "../../../../popups";

import "./profilesPage.scss";
import { useTranslation } from "react-i18next";

import { Button, Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";

import { Checkbox, Dropdown, Skeleton } from "../../../../ui";
import {
  setUserInfoAlreadyExist,
  setUserInfo,
} from "@/stores/slices/userSlice";
import RequestVerPopup from "@/components/popups/requestVerPopup/RequestVerPopup";
import FastVerification from "@/components/popups/fastVerification/FastVerification";
import AddOrImportProfile from "../../../../popups/addOrImportProfile/AddOrImportProfile";
import { setIndiProfiles } from "@/stores/slices/lkIndiSlice";
import useTitle from "@/hooks/useTitle";
import { setElitePrice } from "@/stores/slices/userSlice";
import AgencyFilter from "@/components/popups/agencyFilter/AgencyFilter";
import { AgencyProfileActions } from "../../agency/agencyProfileActions/AgencyProfileActions";

const Res = ({
  isLoading,
  options,
  view,
  variant,
  filteredOptions,
  setData,
  setCount,
  dataInfo,
  verRef,
  setCurrentSlug,
  setCurrentType,
  healthy_boost,
  isHealthBoosted,
  agencySelectCheckbox,
  setAgencySelectCheckbox,
}) => {
  const skeletons = [...Array(12)].map((_, index) => <Skeleton key={index} />);

  const { t } = useLadyService();

  switch (true) {
    case isLoading: {
      return <div className={`profiles-page__grid-ads small`}>{skeletons}</div>;
    }

    case !!filteredOptions.length: {
      return (
        <>
          <div className={`profiles-page__grid-ads${variant}`}>
            {filteredOptions.map((data) => {
              return (
                <AdCard
                  setAgencySelectCheckbox={setAgencySelectCheckbox}
                  agencySelectCheckbox={agencySelectCheckbox}
                  healthyAt={dataInfo.healthy_at}
                  isHealthBoosted={isHealthBoosted}
                  healthy_boost={healthy_boost}
                  data={data}
                  view={view}
                  dataInfo={dataInfo}
                  setData={setData}
                  setCount={setCount}
                  key={data.slug}
                  verRef={verRef}
                  setCurrentSlug={setCurrentSlug}
                  setCurrentType={setCurrentType}
                />
              );
            })}
          </div>
        </>
      );
    }

    case !filteredOptions.length: {
      return <span className="w-100 text-center">{t("emptyrecord")}</span>;
    }

    case !options.length: {
      return <span className="w-100 text-center">{t("zeroads")}</span>;
    }

    default: {
      return <div className={`profiles-page__grid-ads small`}>{skeletons}</div>;
    }
  }
};

const ProfilesPage = () => {
  const {
    windowWidth,
    lang,
    dispatch,
    scrollToTop,
    getDayMonthYear,
    userType,
  } = useLadyService();
  const verRef = useRef(null);
  const healthRef = useRef(null);
  const [dataInfo, setDataInfo] = useState({});
  const {
    profiles_count,
    healthy_at,
    verified_at,
    super_verify_photo,
    verify_count,
    healthy_count,
    verify_wait_count,
    verify_decline_count,
    healthy_boost,
  } = dataInfo;

  const isAgency = userType === "agency";

  const [count, setCount] = useState(profiles_count);
  const [isLoadingInfo, setIsLoadingInfo] = useState(true);
  const [data, setData] = useState([]);
  const [currentSlug, setCurrentSlug] = useState("");
  const [currentType, setCurrentType] = useState("verification");
  const [isHealthBoosted, setIsHealthBoosted] = useState(false);

  useEffect(() => {
    if (!!healthy_boost) {
      setIsHealthBoosted(true);
    }
  }, [healthy_boost]);

  const [isLoading, setIsLoading] = useState(true);

  const healthy = moment.unix(healthy_at).utc().format("DD.MM.YYYY");
  const verified = moment.unix(verified_at).utc().format("DD.MM.YYYY");

  const [search, setSearch] = useState("");

  const filteredOptions = !!search
    ? data.filter(
        (data) =>
          data.name.toLowerCase().includes(search.toLowerCase()) ||
          data.phone?.includes(String(search))
      )
    : data;

  const handleFilter = useCallback((e) => {
    const val = e.currentTarget.value;
    setSearch(val);
  }, []);

  const [view, setView] = useState("standart");

  const { t } = useTranslation();

  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isAgencyFilterOpen, setIsAgencyFilterOpen] = useState(false);

  const currentTimestamp = new Date().getTime();

  const handleOpenImport = () => {
    setIsOpenImport(true);
  };

  const [genderChecked, setGenderChecked] = useState([]);
  const [activeToggle, setActiveToggle] = useState([]);
  const [extraChecked, setExtraChecked] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  const [makeEliteAgency, setMakeEliteAgency] = useState(false);

  const getCombinedArray = () => {
    return [...genderChecked, ...activeToggle, ...extraChecked];
  };
  useEffect(() => {
    const combinedArray = getCombinedArray();

    setFilterCount(combinedArray.length);
  }, [genderChecked, activeToggle, extraChecked]);

  const [allSlugs, setAllSlugs] = useState([]);

  const resetFilter = () => {
    setGenderChecked([]);
    setActiveToggle([]);
    setExtraChecked([]);
  };
  const [agencySelectCheckbox, setAgencySelectCheckbox] = useState([]);

  const fzForStatistic = windowWidth && windowWidth < 1200 ? "fz-14" : "fz-16";

  const isSqure = windowWidth < 670;

  const scrollToVer = () => {
    if (verRef.current) {
      verRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToHealt = () => {
    if (healthRef.current) {
      healthRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  let variant;
  switch (true) {
    case windowWidth < 1199: {
      variant = " small";
      break;
    }
    case view === "standart": {
      variant = "";
      break;
    }

    default:
    case view !== "standart": {
      variant = " small";
      break;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingInfo(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        if (data) {
          const result = data.data;
          setCount(result.profiles_count);
          setDataInfo(result);
          if (result.phone) {
            dispatch(setUserInfo({ stateName: "phone", value: result.phone }));
          }
          if (result.email) {
            dispatch(setUserInfo({ stateName: "email", value: result.email }));
          }
          dispatch(setUserInfoAlreadyExist());
          dispatch(
            setElitePrice({
              oldPrice: result.old_elite_price,
              currentPrice: result.elite_price,
            })
          );
        }

        setIsLoadingInfo(false);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const route = `user/profiles`;

        const method = "GET";
        const payload = {
          limit: 1000,
        };

        const { profiles } = await makeRequest({
          route,
          method,
          payload,
        });

        // function sortByCurrentPosition(arr) {
        //   return arr.sort((a, b) => a.current_position - b.current_position);
        // }

        // const newData = sortByCurrentPosition(profiles);
        if (isAgency) {
          setAllSlugs(profiles.map((item) => item.slug));
        }
        setData(profiles);
        dispatch(setIndiProfiles(profiles));

        setIsLoading(false);
      } catch (error) {}
    };

    lang && fetchData();
  }, [lang]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <main>
        <section className={"profiles-page__container"}>
          <div className={"profiles-page__header"}>
            <h1>
              {t("myadsall")}: {count}
            </h1>

            <VerHeader
              scrollToVer={scrollToVer}
              scrollToHealt={scrollToHealt}
              isLoading={isLoadingInfo}
              data={dataInfo}
            />
          </div>

          <AddHeader handleOpenImport={handleOpenImport} />

          <div className="profiles-page view">
            <Button
              onClick={() => setView("standart")}
              clazz={"profiles-page view__btn"}
            >
              <Icon
                size={"l"}
                spritePath={"longer-view"}
                clazz={view === "standart" ? "" : "_gray"}
              />
              <span className={`p2 ${view === "standart" ? "color-main" : ""}`}>
                {t("standartview")}
              </span>
            </Button>
            <Button
              onClick={() => setView("small")}
              clazz={"profiles-page view__btn"}
            >
              <Icon
                size={"l"}
                spritePath={"small-view"}
                clazz={view === "small" ? "" : "_gray"}
              />
              <span className={`p2 ${view === "small" ? "color-main" : ""}`}>
                {t("minview")}
              </span>
            </Button>

            <div className="search">
              <label className="search__label header__search--label">
                <input
                  placeholder={t("modelname") + ", " + t("phonenumber")}
                  value={search}
                  className={`search__input`}
                  onChange={handleFilter}
                  type={"search"}
                />

                {!!search && (
                  <Button
                    title={t("reset")}
                    onClick={() => setSearch("")}
                    clazz="search__close"
                  >
                    <Icon size={"m"} spritePath={"close"} />
                  </Button>
                )}
                <Button
                  square={true}
                  size={"xs"}
                  clazz="search__icon"
                >
                  <Icon size={"m"} spritePath={"search"} />
                </Button>
              </label>
            </div>

            {isAgency && (
              <AgencyProfileActions
                setMakeEliteAgency={setMakeEliteAgency}
                resetFilter={resetFilter}
                filterCount={filterCount}
                setIsAgencyFilterOpen={setIsAgencyFilterOpen}
                setAgencySelectCheckbox={setAgencySelectCheckbox}
                allSlugs={allSlugs}
                agencySelectCheckbox={agencySelectCheckbox}
              />
            )}

            {windowWidth < 1199 && (
              <>
                <div
                  onClick={scrollToVer}
                  className={"td-none ver__block__control ml-auto"}
                >
                  <Icon
                    spritePath={"verify-fill"}
                    size={isSqure ? "m" : "l"}
                    clazz={
                      (!!verified_at || !!super_verify_photo) &&
                      currentTimestamp < verified_at * 1000
                        ? "_green"
                        : "_red"
                    }
                  />
                  {!isSqure &&
                    ((!!verified_at || super_verify_photo) &&
                    currentTimestamp < verified_at * 1000 ? (
                      <span className="p3 color-600">
                        {t("valid")} {t("until")} {verified}
                      </span>
                    ) : (
                      <span className="p3 color-red-700">
                        {t("not")} {t("valid")}
                      </span>
                    ))}
                </div>

                <div
                  onClick={scrollToHealt}
                  className={"td-none ver__block__control"}
                >
                  <Icon
                    spritePath={"health-fill"}
                    size={isSqure ? "m" : "l"}
                    clazz={
                      !!healthy_at && currentTimestamp < healthy_at
                        ? "_green"
                        : "_red"
                    }
                  />
                  {!isSqure &&
                    (!!healthy_at && currentTimestamp < healthy_at ? (
                      <span className="p3 color-600">
                        {t("valid")} {t("until")} {healthy}
                      </span>
                    ) : (
                      <span className="p3 color-red-700">
                        {t("not")} {t("valid")}
                      </span>
                    ))}
                </div>
              </>
            )}
          </div>

          <Res
            setAgencySelectCheckbox={setAgencySelectCheckbox}
            agencySelectCheckbox={agencySelectCheckbox}
            isHealthBoosted={isHealthBoosted}
            healthy_boost={healthy_boost}
            setCurrentType={setCurrentType}
            setCurrentSlug={setCurrentSlug}
            dataInfo={dataInfo}
            isLoading={isLoading}
            options={data}
            view={view}
            variant={variant}
            verRef={verRef}
            healthRef={healthRef}
            search={search}
            filteredOptions={filteredOptions}
            setData={setData}
            setCount={setCount}
          />
          {!!count && (
            <VerificationAndHealthCheck
              isHealthBoosted={isHealthBoosted}
              setCurrentType={setCurrentType}
              verRef={verRef}
              healthRef={healthRef}
              data={dataInfo}
              setDataInfo={setDataInfo}
              profiles={data}
            />
          )}
          <div
            className={`d-flex gap-16 justify-sb ${
              windowWidth < 998 ? "fd-column" : "fd-row"
            }`}
          >
            <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-green ${fzForStatistic}`}>
                {t("verified")}
              </span>
              <span
                className={`${fzForStatistic} color-main`}
              >
                {verify_count}
              </span>
            </div>
            <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-main ${fzForStatistic}`}>
                {t("moderated24hour")}
              </span>
              <span className={`${fzForStatistic} color-main`}>
                {verify_wait_count || 0}
              </span>
            </div>
            <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-red-700 ${fzForStatistic}`}>
                {t("declinedbymoderator")}
              </span>
              <span className={`${fzForStatistic} color-main`}>
                {verify_decline_count || 0}
              </span>
            </div>
            <div className="verads control p-24 justify-sb fd-row align-center">
              <span className={`color-green ${fzForStatistic}`}>
                {t("healthy")}
              </span>
              <span
                className={`color-main`}
              >
                {healthy_count || 0}
              </span>
            </div>
          </div>
        </section>
      </main>

      <RequestVerPopup slug={currentSlug} />

      <FastVerification
        setIsHealthBoosted={setIsHealthBoosted}
        type={currentType}
        slug={currentSlug}
      />

      {isOpenImport && (
        <ImportAdPopup open={isOpenImport} setOpen={setIsOpenImport} />
      )}

      {isAgencyFilterOpen && (
        <AgencyFilter
          genderChecked={genderChecked}
          setGenderChecked={setGenderChecked}
          activeToggle={activeToggle}
          setActiveToggle={setActiveToggle}
          extraChecked={extraChecked}
          setExtraChecked={setExtraChecked}
          open={isAgencyFilterOpen}
          setOpen={setIsAgencyFilterOpen}
          resetFilter={resetFilter}
        />
      )}

      {makeEliteAgency && (
        <MakeEliteAdOnePopup
          open={makeEliteAgency}
          setOpen={setMakeEliteAgency}
          profiles={data.filter((profile) =>
            agencySelectCheckbox.includes(profile.slug)
          )}
        />
      )}

      <AddOrImportProfile setOpen={setIsOpenImport} />
    </>
  );
};

export default ProfilesPage;
