import { Button, InputInLabel } from "@/components/ui";

import React, { useState } from "react";

import { CheckPhonePopup } from "../../../../popups";

import "./checkPhone.scss";
import CheckPhoneTags from "@/helper/checkPhoneTags";
import useLadyService from "@/services/LadyService";
import { LoadingButton } from "@/components/ui";

const CheckPhone = () => {
  const {t} = useLadyService();
  const {checkTags} = CheckPhoneTags()
  const [phoneClient, setPhoneClient] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState([])

  const handleCheck = async () => {
    setLoading(true)
    const result = await checkTags(phoneClient);
    
    if(result === "error-empty") {
      setInputError(true);
      setLoading(false)

      return
    }
    if(result) {
      setTags(result);
      setIsOpen(true);
    }
    setLoading(false)
  };

  return (
    <>
      <div className="check__root">
        <span className="p1 color-main w-100">{t("crosscheck")}</span>

        {/* <p className="check__info"> */}
          {/* {t("checkteg")} */}
          {/* <span className="check__try">
            {t("todayavailable")} 3 {t("checks")}
          </span> */}
        {/* </p> */}

        <div className="check__form">
          <InputInLabel
            type={"tel"}
            id={"phoneClient"}
            placeholder={t("phonenumber")}
            onChange={(e) => {
              if(inputError) {
                setInputError(false)
              }
            }}
            maskValue={phoneClient}
            setMaskValue={setPhoneClient}
            clazz={inputError ? "error" : ""}
          >
            {inputError ? t('required') : t("phonenumber")}
          </InputInLabel>

          <LoadingButton
            size={"s"}
            isLoading={loading}
            clazz={"button--tetriary"}
            title={t("crossnumber")}
            disabled={!phoneClient}
            buttonType={'submit'}
            onClick={() => handleCheck()}
          >
            {t("crossnumber")}
          </LoadingButton>
        </div>
      </div>

        <CheckPhonePopup
         setTags={setTags}
          options={tags}
          open={isOpen}
          setOpen={setIsOpen}
          phoneClient={phoneClient}
        />
    </>
  );
};

export default CheckPhone;
