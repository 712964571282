import React, {useState} from "react";
import {Button, ErrorMessage, InputInLabel, Select} from "../../../../ui";
import {useForm} from "react-hook-form";
import useLadyService from "@/services/LadyService";
import showToast from "../../../../toast/Toast";
import {makeRequest} from "@/services/makeRequest";
import {LangAi} from "../../createProfile/pageComponent";

export const AgencyDesc = () => {
  const {t, lang} = useLadyService();
  const [isActive, setIsActive] = useState(false);

  const {register, handleSubmit, setValue} = useForm({mode: "onSubmit"});

  // const [currentLang, setCurrentLang] = useState(lang);
  const [agencyDesc, setAgencyDesc] = useState({
    ru: "",
    en: "",
    cz: "",
  });
  const [langAi, setLangAi] = useState(lang);
  const [selectedLangsAi, setSelectedLangsAi] = useState("");
  const changeAgencyDesc = (e) => {
    const value = e.target.value;
    const newValue = {...agencyDesc};
    newValue[langAi] = value;
    setAgencyDesc(newValue);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setIsActive(true);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    // const { email: newEmail, telegram: newTelegram, name: newName } = data;

    // const equalData = {
    //   email: newEmail,
    //   telegram: newTelegram,
    // };

    // const isFilesChanged = !_.isEqual(resData, equalData);

    // const fetchData = async () => {
    //   try {
    //     const route = `user`;
    //     const method = "PUT";
    //     const payload = {
    //       email: newEmail,
    //       telegram: newTelegram,
    //     };

    //     setIsActive(false);
    //     showToast({
    //       message: t("success"),
    //       variant: "success",
    //     });

    //     await makeRequest({ route, method, payload });
    //   } catch (error) {
    //     const res = error.response;

    //     // eslint-disable-next-line default-case
    //     switch (res.status) {
    //       case 401: {
    //         showToast({
    //           message: t("noauth"),
    //           variant: "error",
    //         });
    //         break;
    //       }
    //       case 422: {
    //         showToast({
    //           message: t("oops"),
    //           variant: "error",
    //         });
    //         break;
    //       }
    //     }
    //   }
    // };

    // isFilesChanged ? fetchData() : setIsActive(false);
  };
  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="d-flex gap-16">
        <InputInLabel
          type={"text"}
          id={"name_id"}
          placeholder={t("entername")}
          register={{...register("name")}}
          clazz={"w-100"}
        >
          {t("name")} {t("allagencies").split(" ")[1]}
        </InputInLabel>

        <InputInLabel
          type={"text"}
          id={"name_id"}
          placeholder={"https://example.com"}
          register={{...register("name")}}
          clazz={"w-100"}
        >
          {t("yoursite")}
        </InputInLabel>
      </div>

      <h3>{t("agencydesc")}</h3>

      <InputInLabel
        clazz={"gap-16"}
        type={"textarea"}
        id={"agency-lang"}
        placeholder={""}
        value={agencyDesc[langAi]}
        onChange={(e) => changeAgencyDesc(e)}
      >
        {t("symbols") + ":"} {agencyDesc.length || 0}
        /400
        {agencyDesc.length === 400 && (
          <ErrorMessage message={`${t("maxval")} 400 ${t("symbols")}`}/>
        )}
      </InputInLabel>

      <div className="d-flex gap-8 align-center mt-16">
        <LangAi
          langAi={langAi}
          setLangAi={setLangAi}
          selectedLangsAi={selectedLangsAi}
          setSelectedLangsAi={setSelectedLangsAi}
        />

        <Button
          size={"s"}
          disabled={!isActive}
          onClick={isActive && handleChange}
          buttonType={isActive ? "submit" : 'button'}
          clazz={
            "button_outline--green-accent justify-center ml-auto w-160"
          }
        >
          {t("save")}
        </Button>
      </div>

    </form>
  );
};
