import {ButtonLink} from "../../../../ui";
import useLadyService from "../../../../../services/LadyService";

const InfoBlock = ({link, linkText, text, count, clazzText, balance}) => {
  const {t} = useLadyService()

  return (
    <div className={"account-page__link-block"}>
      <span className={`title${clazzText ? ` ${clazzText}` : ''}`}>
        {text}{': '}{balance ? <span className={'currency--erocoin color-green'}>{count}</span> : count}
      </span>

      {link &&
        <ButtonLink
          href={link}
          clazz={"td-none button_outline--green-accent wd-160"}
          size={"s"}
        >
          {linkText ? linkText : t("goto")}
        </ButtonLink>
      }
    </div>
  )
}

export default InfoBlock