import {Fragment, useEffect, useState} from "react";
import { Button, ErrorMessage, Icon, SelectCountry, Loader } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import GeoInput from "@/components/ui/input/GeoInput";
import { convertData } from "@/helper/convertData";
import { Map } from "../../createProfile/pageComponent";
import { AgencyWrapper } from "./AgencyWrapper";
import { AgencyRowWrapper } from "./AgencyRowWrapper";
import useHandleLocationClick from "@/services/HandleLocationClick";
import showToast from "../../../../toast/Toast";
import { makeRequest } from "@/services/makeRequest";

import "./addressRow.scss";

export const AddressRow = ({
  onRemove,
  onChange,
  onAdd,
  cities = [],
  isActive,
  formCity,
  setFormCity,
  selectedCity,
  setSelectedCity,
  currentActive,
  setCurrentActive,
  setBounds,
  bounds,
  activeCountry,
  setActiveCountry,
  activeAddress,
  setActiveAddress,
  setIsActive,
  addresses,
  mapMarker,
  setMapMarker,
  email,
  phone,
}) => {
  const { t, userType, userTypeAgency } = useLadyService();

  const citiesOptions = convertData(cities);
  const [cityError, setCityError] = useState("");
  const [geoError, setGeoError] = useState(false);

  const handleChangeActive = (e) => {
    e.preventDefault();
    setIsActive(true);
  };
  const [isAddressActive, setIsAddressActive] = useState(false);
  const throwError = (e) => {
    if (
      !e.target.classList.contains("icon--location") &&
      !e.target.classList.contains("button--tetriary")
    ) {
      if (!selectedCity) {
        setGeoError(true);

        setTimeout(() => {
          setGeoError(false);
        }, 2000);
      }
    }
  };

  const handleSubmit = () => {
    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "PUT";
        const payload = {
          email: email,
          phone: phone,
          address: addresses[activeAddress].address,
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          setIsActive(false);
          showToast({
            message: t("success"),
            variant: "success",
          });
        }
      } catch (error) {
        const res = error.response;

        // eslint-disable-next-line default-case
        switch (res.status) {
          case 401: {
            showToast({
              message: t("noauth"),
              variant: "error",
            });
            break;
          }
          case 422: {
            showToast({
              message: t("oops"),
              variant: "error",
            });
            break;
          }
        }
      }
    };

    fetchData();
  };

  const handleLocationClick = useHandleLocationClick(
    setFormCity,
    setActiveCountry,
    setBounds,
    setCurrentActive,
    setSelectedCity,
    activeCountry,
    cities,
    setIsAddressActive,
    setMapMarker
  );

  const [currentCityAgency, setCurrentCityAgency] = useState('');
  const handleChange = (e, id) => {
    const { name } = e;
    onChange(id, name ? "city" : "address", name || e);
  };

  useEffect(() => {
    if (addresses.length > 0) {
      const activeAddressItem = addresses[activeAddress];
      if (activeAddressItem) {
        setCurrentCityAgency(activeAddressItem.city);
      }
    }
  }, [addresses, activeAddress]);

  return (
    <AgencyWrapper isActive={isActive} setIsActive={setIsActive} onAdd={onAdd}>
      <div
        className={`info-address ${
          userTypeAgency ? "info-address__agency" : ""
        }`}
      >
        {addresses.length
          ? addresses.map((item, index) => {
            // console.log(item)
            // console.log('cities[activeCountry].name', cities[activeCountry].name)
            // console.log('selectedCity', selectedCity)

              return (
                <Fragment key={index}>
                  <AgencyRowWrapper
                    isActive={isActive}
                    index={index}
                    activeAddress={activeAddress}
                    setActiveAddress={setActiveAddress}
                  >
                    <SelectCountry
                      data={citiesOptions}
                      name={"create-city"}
                      clazz={`${cityError ? "_error" : ""} size-l-forever justify-start select`}
                      clazzSvg={`select__flag ${formCity?.icon || ""}`}
                      arrowSize={"m"}
                      type={"flag"}
                      placeholder={t("yourcity")}
                      defaultTitle={
                        userTypeAgency ? currentCityAgency : selectedCity || formCity?.name
                      }
                      subTitle={t("yourcity")}
                      withoutIcon={userTypeAgency ? !currentCityAgency : !selectedCity}
                      setValue={userTypeAgency ? setCurrentCityAgency : setSelectedCity}
                      currentActiveIndex={currentActive}
                      setActiveCountry={setActiveCountry}
                      activeCountry={activeCountry}
                      setActive={setCurrentActive}
                      error={cityError}
                      errorMessage={<ErrorMessage message={cityError} />}
                      onChange={(e) => {
                        if (userTypeAgency) {
                          handleChange(e, item.id);
                        }
                        if (cityError) {
                          setCityError("");
                        }
                      }}
                      setError={setCityError}
                      setIsAddressActive={setIsAddressActive}
                    />

                    <div className={`search filter__map-input${userTypeAgency ? ` align-center` : ''}`}>
                      <div
                        className={`search filter__map-input ${
                          selectedCity ? "" : "geo-error"
                        }`}
                        onClick={(e) => {
                          if (isActive) {
                            throwError(e);
                          }
                        }}
                      >
                        <span className={"filter__map-title"}>{t("youraddress")}</span>
                        <label
                          htmlFor="geosuggest__input-create"
                          className="search__label header__search"
                        >
                          <GeoInput
                            value={item.address}
                            setValue={handleChange}
                            name={"location-create"}
                            id="geosuggest__input-create"
                            clazz={`header__search-input ${geoError ? "_error" : ""}`}
                            placeholder={t("fulladdress")}
                            bounds={bounds}
                            isAgency={true}
                          />

                          {geoError && <ErrorMessage message={t("cityfirst")} />}
                        </label>
                      </div>
                      <Button
                        title={t("chekmyplace")}
                        onClick={handleLocationClick}
                        name={"location"}
                        square={true}
                        size={"l"}
                        clazz={
                          isAddressActive
                            ? "search__icon button--tetriary active"
                            : "search__icon button--tetriary"
                        }
                      >
                        <Icon size={"xl"} spritePath={"location"} />
                      </Button>

                      {userTypeAgency && (
                        <button
                          type={'button'}
                          className={`tarifs-row-trash icon icon-xl icon--trash _red`}
                          onClick={() => onRemove(item.id)}
                        />
                      )}
                    </div>

                    {!userTypeAgency &&
                    (activeAddress === index &&
                      selectedCity &&
                      item.city) ? (
                      <Map
                        setMarkerActive={setIsAddressActive}
                        markerActive={isAddressActive}
                        region={cities[activeCountry].name}
                        city={selectedCity}
                        setBounds={setBounds}
                        coords={mapMarker}

                        // setMarkerActive={setIsAddressActive}
                        // markerActive={isAddressActive}
                        // region={activeCountry}
                        // city={selectedCity}
                        // setBounds={setBounds}
                        // coords={mapMarker}
                      />
                    ) : <Loader height={300} decimal={'px'}/>}

                  </AgencyRowWrapper>
                </Fragment>
              );
            })
          : null}
      </div>

      {!userTypeAgency && (
        <Button
          size={"s"}
          onClick={!isActive ? () => handleChangeActive : () => handleSubmit()}
          disabled={!isActive}
          clazz={
            "button_outline--green-accent justify-center w-160 ml-auto"
          }
        >
          {t("save")}
        </Button>
      )}
    </AgencyWrapper>
  );
};
