import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  email: "",
  email_verified_at: null,
  phone: "",
  phone_verified_at: null,
  telegram: null,
  whatsapp: null,
  notify: {
    email: false,
    telegram: false,
    whatsapp: false,
  },
  rating: 0,
  city: "",
  cities: [],
  city_id: null,
  address: "",
  latitude: null,
  longitude: null,
  filters_count: 0,
  actions_count: 0,
  elite_price: 0,
  old_elite_price: 0,
  profiles: {
    count: 0,
    status: 0,
  },
  accept_booking: 0,
  accept_video: 0,
  salon: 0,

  verify: {
    status: 0,
    count: 0,
    decline_count: 0,
    wait_count: 0,
    photo: {
      link: "",
      moderated: 0,
    },
  },

  healthy: {
    boost: false,
    at: null,
    photo: [],
  },

  is_super_verified: false,
  verified_at: null,
  blocked_countries: [],

  agency: {
    id: 1,
    slug: "",
    name: "",
    addresses: [],
    phone: null,
    description: {
      en: {
        description: "",
      },
      cz: {
        description: "",
      },
      ru: {
        description: "",
      },
    },
    description_translate_to: [],
    whatsapp: "",
    telegram: "",
    website: "",
    settings: {
      preview_price: "",
      tariffs: null,
      work_time: null,
      discount: null,
    },
    logo: [],
    promotion: [],
    full_promotion: [],
    interior: [],
    cover: [],
    image: [],
    video: [],
  },
};

const userSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setNameStatus: (state, action) => {
      state.name = action.payload;
    },
    setEmailStatus: (state, action) => {
      state.email = action.payload;
    },
    setEmailVerifiedAtStatus: (state, action) => {
      state.email_verified_at = action.payload;
    },
    setPhoneStatus: (state, action) => {
      state.phone = action.payload;
    },
    setPhoneVerifiedAtStatus: (state, action) => {
      state.phone_verified_at = action.payload;
    },
    setTelegramStatus: (state, action) => {
      state.telegram = action.payload;
    },
    setWhatsappStatus: (state, action) => {
      state.whatsapp = action.payload;
    },
    setNotifyEmailStatus: (state, action) => {
      state.notify.email = action.payload;
    },
    setNotifyTelegramStatus: (state, action) => {
      state.notify.telegram = action.payload;
    },
    setNotifyWhatsappStatus: (state, action) => {
      state.notify.whatsapp = action.payload;
    },
    setProfileStatus: (state, action) => {
      state.profiles.status = action.payload;
    },
    setSalonStatus: (state, action) => {
      state.salon = action.payload;
    },
    setCityStatus: (state, action) => {
      state.city = action.payload;
    },
    setCityIdStatus: (state, action) => {
      state.city_id = action.payload;
    },
    setLatitudeStatus: (state, action) => {
      state.latitude = action.payload;
    },
    setAddressStatus: (state, action) => {
      state.address = action.payload;
    },
    setLongitudeStatus: (state, action) => {
      state.longitude = action.payload;
    },
    setCitiesListStatus: (state, action) => {
      state.cities = action.payload;
    },
    setProfilesCountStatus: (state, action) => {
      state.profiles.count = action.payload;
    },
    setFiltersCountStatus: (state, action) => {
      state.filters_count = action.payload;
    },
  setActionsCountStatus: (state, action) => {
      state.actions_count = action.payload;
    },
    setVerifyPhotoLinkStatus: (state, action) => {
      state.verify.photo.link = action.payload;
    },
    setVerifyPhotoModeratedStatus: (state, action) => {
      state.verify.photo.moderated = action.payload;
    },
    setAgencyDescriptionEnStatus: (state, action) => {
      state.agency.description.en.description = action.payload;
    },
    setAgencyDescriptionCzStatus: (state, action) => {
      state.agency.description.cz.description = action.payload;
    },
    setAgencyDescriptionRuStatus: (state, action) => {
      state.agency.description.ru.description = action.payload;
    },
    setAgencySettingsPreviewPriceStatus: (state, action) => {
      state.agency.settings.preview_price = action.payload;
    },
    setAgencySettingsTariffsStatus: (state, action) => {
      state.agency.settings.tariffs = action.payload;
    },
    setAgencySettingsWorkTimeStatus: (state, action) => {
      state.agency.settings.work_time = action.payload;
    },
    setAgencySettingsDiscountStatus: (state, action) => {
      state.agency.settings.discount = action.payload;
    },
    setAgencyLogoStatus: (state, action) => {
      state.agency.logo = action.payload;
    },
    setAgencyPromotionStatus: (state, action) => {
      state.agency.promotion = action.payload;
    },
    setBlockedCountriesList: (state, action) => {
      state.blocked_countries = action.payload;
    },
  },
});

export const {
  setNameStatus,
  setEmailStatus,
  setEmailVerifiedAtStatus,
  setPhoneStatus,
  setSalonStatus,
  setPhoneVerifiedAtStatus,
  setTelegramStatus,
  setWhatsappStatus,
  setNotifyEmailStatus,
  setNotifyTelegramStatus,
  setNotifyWhatsappStatus,
  setFiltersCountStatus,
  setActionsCountStatus,
  setProfileStatus,
  setProfilesCountStatus,
  setVerifyPhotoLinkStatus,
  setVerifyPhotoModeratedStatus,
  setAgencyDescriptionEnStatus,
  setAgencyDescriptionCzStatus,
  setAgencyDescriptionRuStatus,
  setAgencySettingsPreviewPriceStatus,
  setAgencySettingsTariffsStatus,
  setAgencySettingsWorkTimeStatus,
  setAgencySettingsDiscountStatus,
  setAgencyLogoStatus,
  setAgencyPromotionStatus,
  setCitiesListStatus,
  setAddressStatus,
  setLatitudeStatus,
  setLongitudeStatus,
  setCityStatus,
  setCityIdStatus,
  setBlockedCountriesList,
} = userSlice.actions;

export const getNameStatus = (state) => state.userProfile.name;
export const getEmailStatus = (state) => state.userProfile.email;
export const getEmailVerifiedAtStatus = (state) => state.userProfile.email_verified_at;
export const getPhoneStatus = (state) => state.userProfile.phone;
export const getPhoneVerifiedAtStatus = (state) => state.userProfile.phone_verified_at;
export const getTelegramStatus = (state) => state.userProfile.telegram;
export const getWhatsappStatus = (state) => state.userProfile.whatsapp;
export const getNotifyEmailStatus = (state) => state.userProfile.notify.email;
export const getNotifyTelegramStatus = (state) => state.userProfile.notify.telegram;
export const getNotifyWhatsappStatus = (state) => state.userProfile.notify.whatsapp;
export const getProfilesCountStatus = (state) => state.userProfile.profiles.count;
export const getProfileStatus = (state) => state.userProfile.profiles.status;
export const getFiltersCountStatus = (state) => state.userProfile.filters_count;
export const getActionsCountStatus = (state) => state.userProfile.actions_count;
export const getVerifyPhotoLinkStatus = (state) => state.userProfile.verify.photo.link;
export const getVerifyPhotoModeratedStatus = (state) => state.userProfile.verify.photo.moderated;
export const getAgencyDescriptionEnStatus = (state) => state.userProfile.agency.description.en.description;
export const getAgencyDescriptionCzStatus = (state) => state.userProfile.agency.description.cz.description;
export const getAgencyDescriptionRuStatus = (state) => state.userProfile.agency.description.ru.description;
export const getAgencySettingsPreviewPriceStatus = (state) => state.userProfile.agency.settings.preview_price;
export const getAgencySettingsTariffsStatus = (state) => state.userProfile.agency.settings.tariffs;
export const getAgencySettingsWorkTimeStatus = (state) => state.userProfile.agency.settings.work_time;
export const getAgencySettingsDiscountStatus = (state) => state.userProfile.agency.settings.discount;
export const getAgencyLogoStatus = (state) => state.userProfile.agency.logo;
export const getAgencyPromotionStatus = (state) => state.userProfile.agency.promotion;
export const getSalonStatus = (state) => state.userProfile.salon;
export const getCitiesListStatus = (state) => state.userProfile.cities;
export const getLongitudeStatus = (state) => state.userProfile.longitude;
export const getLatitudeStatus = (state) => state.userProfile.latitude;
export const getCityIdStatus = (state) => state.userProfile.city_id;
export const getCityStatus = (state) => state.userProfile.city;
export const getAddressStatus = (state) => state.userProfile.address;
export const getBlockedCountriesList = (state) => state.userProfile.blocked_countries;


export default userSlice.reducer;