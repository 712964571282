import {useRef} from "react";
import useLadyService from "@/services/LadyService";
import TariffsTable from "../../../tariffsTable/TariffsTable";
import {InfoBlock, InfoForm, Rules} from "../components";
import NotifyBlock from "../components/notifyBlock/NotifyBlock";
import {PersonalData} from "../components/PersonalData";
import AccountActions from "../components/accountActions/AccountActions";
import useUserProfileService from "@/services/UserProfileService";
const AccountCustomer = () => {
  const {
    windowWidth,
    t,
    userBalance
  } = useLadyService();

  const {
    filtersCountStatus,
    actionsStatus
  } = useUserProfileService();

  const isSmallTablet = windowWidth < 1000.98;

  const goToBank = isSmallTablet ? t("tobalance") : t("gotobalance");

  const tariffsRef = useRef(null);

  return (
    <>
      <div className="account-page__root">
        <div className={"account-page__main"}>

          <PersonalData/>

          <hr/>

          {isSmallTablet && (
            <>
              <Rules/>
              <hr/>
            </>
          )}

          <NotifyBlock/>

          <InfoForm/>

          <hr/>

          <div className={"account-page__info"}>
            <InfoBlock
              text={t('subandfilters')}
              count={filtersCountStatus}
              link={!!filtersCountStatus ? '/subscriptions' : ''}
            />

            <InfoBlock
              text={`${t("history")} ${t("profiles")}`}
              count={actionsStatus}
              link={!!actionsStatus ? '/history' : ''}
            />

            <span className="fz-15">{t("adsstore")}</span>

            <InfoBlock
              text={t("yourbalance")}
              count={userBalance}
              balance
              link={'/lk/balance'}
              linkText={goToBank}
            />

          </div>

          <hr/>

          <AccountActions/>

        </div>

        {!isSmallTablet && <Rules/>}

      </div>

      <section id={'tariffs'}>
        <h1 className={"mb-40 mb-24-mob"}>{t("selecttariff")}</h1>

        <TariffsTable refElem={tariffsRef}/>
      </section>
    </>
  );
};

export default AccountCustomer;
