import { GoogleMap, InfoBox, Marker } from "@react-google-maps/api";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { getGmapsApiReady } from "@/stores/slices/mapModelSlice";
import {
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";
import { getOneProfileCoords } from "@/stores/slices/oneProfileSlice";
import useLadyService from "@/services/LadyService";
import { Icon } from "@/components/ui";
import {Loader} from "../../../../../ui";

import './map.scss'

const Map = ({
  city,
  region,
  setBounds,
  coords,
  markerActive,
  setMarkerActive,
  clazz,
}) => {
  const [map, setMap] = useState(null);
  const { lang, dispatch } = useLadyService();
  const apiReady = useSelector(getGmapsApiReady);
  const coordsMap = useSelector(getOneProfileCoords);

  const center =
    !!coordsMap.latitude &&
    isFinite(coordsMap.latitude) &&
    isFinite(coordsMap.longitude)
      ? new window.google.maps.LatLng(
          parseFloat(coordsMap.latitude),
          parseFloat(coordsMap.longitude)
        )
      : {};

  const [zoom, setZoom] = useState(14);

  const mapStyles = {
    height: "100%",
    width: "100%",
    borderRadius: "12px",
  };

  const address = useRef("");
  const [coordinates, setCoordinates] = useState(coords);

  const newCords = {
    lat:
      coordsMap?.latitude && isFinite(coordsMap?.latitude)
        ? parseFloat(coordsMap.latitude)
        : null,
    lng:
      coordsMap?.longitude && isFinite(coordsMap?.longitude)
        ? parseFloat(coordsMap.longitude)
        : null,
  };

  useEffect(() => {
    if (map && apiReady) {
      const geocoder = new window.google.maps.Geocoder();
      const handleGeocodeResults = (results, status) => {
        if (status === "OK" && !!results[0]) {
          const { geometry } = results[0];
          const { location } = geometry;
          const lat = location.lat();
          const lng = location.lng();
          if (!markerActive) {
            map.setCenter({ lat, lng });
          } else {
            map.panTo(coords);
          }
        } else {
          console.error("Ошибка при получении координат города:", status);
        }
      };

      geocoder.geocode({ address: city + ` ${region}` }, handleGeocodeResults);
    }
  }, [city, map]);

  useEffect(() => {
    if (
      map &&
      !!coordsMap.latitude &&
      isFinite(coordsMap.latitude) &&
      isFinite(coordsMap.longitude) &&
      apiReady
    ) {
      map.panTo(center);
      setZoom(14);
    }
  }, [map, markerActive, center]);

  const handleMapClick = (event) => {
    setMarkerActive(true);
    const lat = event.latLng.lat();

    const lng = event.latLng.lng();

    setCoordinates({ lat, lng });
    dispatch(setOneProfileCoords({ latitude: lat, longitude: lng }));

    if (
      map &&
      !!lat &&
      !!lng &&
      isFinite(coordsMap.latitude) &&
      isFinite(coordsMap.longitude) &&
      apiReady
    ) {
      map.panTo(event.latLng);
      setZoom(14);
    }

    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setBounds({ lat: lat, lng: lng });

        address.current = results[0].formatted_address;
        console.log(results);
        function findAddressComponents(arr) {
          const streetNumberComponent = arr.find((component) =>
            component.types.includes("street_number")
          );

          const routeComponent = arr.find((component) =>
            component.types.includes("route")
          );

          const premiseComponent = arr.find((component) =>
            component.types.includes("premise")
          );

          const neighborhoodComponent = arr.find((component) =>
            component.types.includes("neighborhood")
          );

          if (
            (!!streetNumberComponent || !!premiseComponent) &&
            !!routeComponent
          ) {
            return `${routeComponent.long_name}, ${
              streetNumberComponent?.long_name || premiseComponent.long_name
            }`;
          } else if (!!premiseComponent && !!neighborhoodComponent) {
            return `${neighborhoodComponent.long_name}, ${premiseComponent.long_name}`;
          }

          return "";
        }

        const result = findAddressComponents(results[0].address_components);

        dispatch(setOneProfileAddress(result));
      } else {
        console.error("Ошибка при обратном геокодировании:", status);
        address.current = "";
      }
    });
  };

  const isValidLatLng = (position) => {
    return (
      position &&
      "lat" in position &&
      "lng" in position &&
      typeof position.lat === "number" &&
      typeof position.lng === "number" &&
      isFinite(position.lat) &&
      isFinite(position.lng)
    );
  };

  return (
    <div className={`create-page__map${clazz ? ` ${clazz}` : ""}`}>
      {!apiReady ? <Loader height={300} decimal={'px'}/> : (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={zoom}
          onLoad={(map) => setMap(map)}
          lang={lang}
          onClick={handleMapClick}
        >
          {(isValidLatLng(newCords) || isValidLatLng(coordinates)) && (
            <Marker
              icon={{
                path: "M0 0h24v24H0z",
                fillColor: "transparent",
                fillOpacity: 0,
                strokeWeight: 0,
                scale: 0,
              }}
              position={isValidLatLng(newCords) ? newCords : coordinates}
            >
              <InfoBox
                position={isValidLatLng(newCords) ? newCords : coordinates}
                options={{
                  enableEventPropagation: false,
                  boxStyle: {
                    translate: `-50% -100%`,
                    minWidth: `100px`,
                  },
                  closeBoxURL: "",
                }}
              >
                <div className="map-filter__tippy">
                  <Icon spritePath={"location"} size={"xl"} />
                </div>
              </InfoBox>
            </Marker>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default Map;
